.carousel-img {
  height: 600px;
  object-fit: cover;
}

.container {
  flex-wrap: wrap;
}

.loading {
  height: 100%;
  width: 100%
}

.full-page {
  text-align: center;
  width: 100%;
  height: 600px;
  object-fit: contain;
  position:relative;
  overflow: hidden;
  background: #C33764;  /* fallback colour. Make sure this is just one solid colour. */
  background: -webkit-linear-gradient(rgba(29, 38, 113, 0.8), rgba(195, 55, 100, 0.8)), url("https://s3.ap-south-1.amazonaws.com/thedojoblr.com/architectural-images/3.1.jpg");
  background: linear-gradient(rgba(0,0,0, 0.7), rgba(0,0,0, 0.7)), url("https://s3.ap-south-1.amazonaws.com/thedojoblr.com/architectural-images/3.1.jpg"); /* The least supported option. */
  background-size: 100% 800px;
}

.overlay {
}

.text-over-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.white {
  color:white;
}
.text-center {
  align-items: center;
}

@media (min-width: 1200px) {
}

@media (min-width: 992px) and (max-width: 1199px) {
  .index-header {
    text-align:left;
  }

  .index-header-right {
    text-align:left;
  }

  .right-aligned {
    text-align:left;
  }

}

@media (min-width: 768px) and (max-width: 959px) {
  .index-header {
    text-align:left;
  }

  .index-header-right {
    text-align:left;
  }

  .right-aligned {
    text-align:left;
  }

}

@media (min-width: 576px) and (max-width: 767px) {
  .index-header {
    text-align:left;
  }

  .index-header-right {
    text-align:left;
  }

  .right-aligned {
    text-align:left;
  }

}
@media (min-width: 491px) and (max-width: 575px) {
  .index-header {
    text-align:left;
  }

  .index-header-right {
    text-align:left;
  }

  .right-aligned {
    text-align:left;
  }

}
@media (min-width: 348px) and (max-width: 490px) {
  .index-header {
    text-align:left;
  }

  .index-header-right {
    text-align:left;
  }

  .right-aligned {
    text-align:left;
  }


}
@media (max-width: 347px) {
  .index-header {
    text-align:left;
  }

  .index-header-right {
    text-align:left;
  }

  .right-aligned {
    text-align:left;
  }
}
