.App {
  text-align: left;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

p {
  color: #1f101a;
}

h1 {
  color: #8b233f;
}

.dropdown-menu {
  position: absolute;
  top: 48px;
  left: -50px;
  background-color: #dfe0e2;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 0px;
}
.form-control {
  color: #1f101a;
}

.btn-primary {
  color: #fff;
  background-color: #ae8f63!important;
  border-color: #ae8f63!important;
}

.container {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex: wrap;
  padding: 30px;
  /* background-color: #c88b8b; */
  background-image: linear-gradient(101deg, #848889, #dfe0e2 52%, #ae8f63);
  border-bottom: solid 1px #ae8f63;
}

.index-header {
  font-size: 56px;
  text-align: end;
  font-weight: 900;
  color: #8b233f;
}

.index-header-right {
  font-size: 56px;
  text-align: left;
  font-weight: 900;
  color: #8b233f;
}

.right-aligned {
  text-align: end;
}


/* Navbar */
.navbar-light .navbar-nav .nav-link {
  color: white!important;
}

.bg-dark {
  background-color: black!important;
}

.navbar-brand:hover {
  color: rgba(0,0,0,.9);
}

.navbar-light .navbar-brand {
color: white;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
}

@media (min-width: 768px) and (max-width: 959px) {
  .index-header {
    font-size: 42px;
  }

  .index-header-right {
    font-size: 42px;
  }
  .dropdown-menu {
    text-align: center;
    background-color: transparent;
    border: 0px;
  }

}

@media (min-width: 576px) and (max-width: 767px) {
  .index-header {
    font-size: 42px;
  }

  .index-header-right {
    font-size: 42px;
  }
  .dropdown-menu {
    text-align: center;
    background-color: transparent;
    border: 0px;
  }
}
@media (min-width: 491px) and (max-width: 575px) {
  .index-header {
    font-size: 42px;
  }

  .index-header-right {
    font-size: 42px;
  }
  .dropdown-menu {
    text-align: center;
    background-color: transparent;
    border: 0px;
  }

}
@media (min-width: 348px) and (max-width: 490px) {
  .index-header {
    font-size: 42px;
  }

  .index-header-right {
    font-size: 42px;
  }
  .dropdown-menu {
    text-align: center;
    background-color: transparent;
    border: 0px;
  }


}
@media (max-width: 347px) {
  .index-header {
    font-size: 42px;
  }

  .index-header-right {
    font-size: 42px;
  }
  .dropdown-menu {
    text-align: center;
    background-color: transparent;
    border: 0px;
  }


}
