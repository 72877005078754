.full-width {
  width: 100%
}

.img-fluid {
  width: 100%;
  height: 600px;
  object-fit: cover;
  background: black;
  background-image: linear-gradient();
}

.index-header-left {
  font-size: 56px;
  text-align: left;
  font-weight: 900;
  color: #8b233f;
}

.justify {
  text-align: justify;
}

@media (min-width: 992px) and (max-width: 1199px) {
}

@media (min-width: 768px) and (max-width: 959px) {

}

@media (min-width: 576px) and (max-width: 767px) {
  .index-header-left {
    font-size: 42px;
  }
}
@media (min-width: 491px) and (max-width: 575px) {
  .index-header-left {
    font-size: 42px;
  }

}
@media (min-width: 348px) and (max-width: 490px) {
  .index-header-left {
    font-size: 42px;
  }

}
@media (max-width: 347px) {
  .index-header-left {
    font-size: 42px;
  }
}
