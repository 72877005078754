.modal-header {
  display: block;
}

.inv-no {
  text-align: end;
}

.contact-info {
  text-align: start;
}

.table {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.thedojologo {
  height: 80px;
}

.receipt-header {
  color: #8b233f;
}