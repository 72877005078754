select {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid black;
}

select:focus {
  background-color: transparent;
  border-color: transparent;
  outline: 0;
  box-shadow: 0 0 0 0;
  border-bottom: 1px solid black;

}

.space-bottom {
  padding-bottom: 40px;
}
