/* .card-deck .card {
  color:black;
} */

.index-header {
    display: block;
}

.privates-card {
  margin-bottom: 20px;
  border-radius: 100px;
  background: #a18686!important;
}

.privates-card-title {
  margin-bottom: 0px;
}

@media (min-width: 348px) and (max-width: 490px) {
  .privates-cardDeck {
    display: flex!important;
    justify-content: center;
  }

  .privates-card {
    flex: 1 1 100%!important;
    margin-bottom: 20px!important;
    margin-bottom: 20px;

  }

}

@media (min-width: 576px) {
  .privates-cardDeck {
    display: flex!important;
    justify-content: center;
  }

  .privates-card {
    flex: 1 1 30%!important;
    margin-bottom: 20px!important;
  }
}
