.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: 0px;
  padding: 80px 0px 0px 0px;
  background-image: linear-gradient(101deg, #848889, #dfe0e2 52%, #ae8f63);
  color: white;
}

.fixing-newsletter {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.text-center {
  text-align: center!important;
}

.text-center {
  text-align: center!important;
}

.hr-large {
  border-width: 1px 0 0 0;
  width: 100px;
  margin: 40px auto;
  clear: both;
  border-top: solid gray;
}

.rowcontainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 20px;
  margin-left: 20px;
}

.contactinfo {
  text-align: left;
}

.form-control {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid black;
}

.check-in {
  width: 200px;
  margin: auto;
}

.form-control:focus {
  background-color: transparent;
  border-color: transparent;
  outline: 0;
  box-shadow: 0 0 0 0;
  border-bottom: 1px solid black;

}

.card-deck .profile-card {
  flex: auto!important;
  margin-bottom: 15px;
}

.profile-deck {
  justify-content: center;
}

.hidden {
  display: none;
}

.add-member {
  margin-left: 10px;
}

.date-picker {
  background-color: transparent;
}

.center {
  margin-right: auto;
  margin-left: auto;
}

.container {
  overflow-x: auto;
}

.table td, .table th {
  white-space: normal;
}

@media (min-width: 1200px) {
}

@media (min-width: 962px) and (max-width: 1199px) {
  .contactinfo {
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 959px) {
  .contactinfo {
    text-align: center;
  }

}

@media (min-width: 576px) and (max-width: 767px) {
  .contactinfo {
    text-align: center;
  }
}
@media (min-width: 491px) and (max-width: 575px) {
  .contactinfo {
    text-align: center;
  }

}
@media (min-width: 348px) and (max-width: 490px) {
  .contactinfo {
    text-align: center;
  }

}
@media (max-width: 347px) {
  .contactinfo {
    text-align: center;
  }

}
