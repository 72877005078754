.rowcontainersansflex {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-right: 20px;
  padding-left: 20px;
  color: white;
  background-image: linear-gradient(101deg, #848889, #dfe0e2 52%, #ae8f63);
}

.header-center {
  width: 100%;
  padding-bottom: 10px;
  padding-top: 50px;
  color: #8b233f;
}

.textcenter {
  text-align: justify;
  width: 100%;
}

.ul {
  text-align: left!important;
}

@media (min-width: 1200px) {
}
