header {
  border-bottom: 0.1px solid gray;
}

.navbar-dark .navbar-nav .nav-link {
  color: #8b233f;
}

.navbar-nav {
  text-align: center;
}


.login {
  height: 25px;
}

.bg-dark {
  background-image: linear-gradient(101deg, #848889, #dfe0e2 52%, #ae8f63)!important;
}

.navbar-brand {
  padding: 0px;
}

.navbar-brand-img {
  height: 50px;
}
