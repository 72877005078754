/* .card-deck .card {
  color:black;
} */

.card-deck {
  color: black;
}

.none {
  display: none;
}

ul {
  color: black;
}

.qrcode {
  height: 200px;
}

h4 {
  color: #8b233f;
}

.card-deck {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
  }

.card-deck .card {
  background: #dfe0e2;
  padding: 15px;
  flex: auto;
  margin-bottom: 10px;
  margin-right: 15px;
  margin-left: 15px;

}

.list-group-flush>.list-group-item {
  background: #dfe0e2;
}

body {
  margin-top: 0px!important;
}

.modal-header {
  text-align: center;
}

.modal-body {
  text-align: center;
}

.modal-body .form-control {
  text-align: center;
}

.fixed-input {
  text-align: center;
  font-weight: bold;
}

.header-center {
  text-align: center;
  color: #1f101a;
}

.form-control:disabled, .form-control[readonly] {
background-color: transparent!important;
opacity: 1;
}

.card-menu {
  margin: 10px;
}

.card-menu-trial {
  margin: 10px;
  background-color: #79061b !important;
  border-color: #79061b !important;
}

@media (min-width: 992px) {
  .col-lg-4 {
      max-width: calc(33.333% - 30px);
      width:calc(33.333% - 30px);
  }
}

@media (min-width: 768px) and (max-width: 959px) {
  .card-deck {
    display: block;
    margin-right: 15px;
    margin-left: 15px;

  }

  card-deck .card {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .card {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .card-deck {
    display: block;
    margin-right: 15px;
    margin-left: 15px;

  }

  card-deck .card {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .card {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

}
@media (min-width: 491px) and (max-width: 575px) {
  .card-deck {
    display: block;
    margin-right: 15px;
    margin-left: 15px;

  }

  card-deck .card {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .card {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

}
@media (min-width: 348px) and (max-width: 490px) {
  .card-deck {
    display: block;
    margin-right: 15px;
    margin-left: 15px;

  }
  card-deck .card {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .card {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }


}
@media (max-width: 347px) {
  .card-deck {
    display: block;
    margin-right: 15px;
    margin-left: 15px;

  }

  card-deck .card {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .card {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

}
